<template>
  <BModal
    :model-value="store.generalModals.isImprintOpen"
    scrollable
    ok-only
    size="xl"
    title="Impressum"
    @hidden="close">
    <h4>Anschrift und Kontakt</h4>

    <address>
      <strong>Christian-Albrechts-Universität zu Kiel</strong>
      Christian-Albrechts-Platz 4
      <br />
      24118 Kiel, Germany
      <br />
    </address>

    <p>
      Prof. Dr. Markus Gebhardt (
      <a href="mailto:markus.gebhardt@lmu.de">markus.gebhardt@lmu.de</a>
      )
      <br />
      Prof. Dr. Andreas Mühling (
      <a href="mailto:andreas.muehling@informatik.uni-kiel.de">
        andreas.muehling@informatik.uni-kiel.de
      </a>
      )
    </p>

    <br />
    <h2>Haftungsausschluss</h2>

    <br />
    <h4>Haftung für Inhalte</h4>

    <p class="text-justify">
      Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
      allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch
      nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder
      nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen
      zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen
      bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
      Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
      Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
    </p>

    <br />
    <h4>Haftung für Links</h4>
    <p class="text-justify">
      Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen
      Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für
      die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten
      verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche
      Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
      erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne
      konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
      Rechtsverletzungen werden wir derartige Links umgehend entfernen.
    </p>

    <br />
    <h4>Urheberrecht</h4>
    <p class="text-justify">
      Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem
      deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
      Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
      jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den
      privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht
      vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden
      Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
      Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei
      Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
    </p>
  </BModal>
</template>
<script>
  import { useGlobalStore } from 'src/store/store'

  export default {
    name: 'ImprintModal',
    setup() {
      const store = useGlobalStore()
      return { store }
    },

    methods: {
      close() {
        this.store.generalModals.isImprintOpen = false
      },
    },
  }
</script>
